<template>
  <FormKit
    :value="modelValue"
    type="date"
    :name="name"
    :help="help"
    :label="label"
    :min="formatInputDate(min)"
    :max="formatInputDate(max)"
    :floating-label="floatingLabel"
    :validation="getValidation()"
    :validation-label="validationLabel || 'Datum'"
    :validation-visibility="validationVisibility"
    :validation-messages="{
      date_before: getDateBeforeValidationMessage(),
      date_after: getDateAfterValidationMessage(),
      date_between: getDateBetweenValidationMessage(),
    }"
    :convert-empty-string-to-undefined="true"
    :outer-class="outerClass"
    @input="$emit('update:modelValue', $event)"
  />
</template>

<script setup lang="ts">
import { format, addDays, subDays, isToday } from "date-fns";
import formatDate from "~/utils/customer-area/formatDate";

const props = withDefaults(
  defineProps<{
    /** The name for the FormKit element */
    name: string;
    /** The help text for the FormKit element */
    help?: string;
    /**
     * The label for the FormKit element
     */
    label?: string;
    /**
     * Whether the label should float above the input or not.
     */
    floatingLabel?: boolean;
    /**
     * The validation visibility for the FormKit element
     */
    validationVisibility?: "blur" | "live" | "dirty" | "submit";
    /** The human readable name for the FormKit element used for validation errors */
    validationLabel?: string;
    /** The earliest acceptable date */
    min?: Date;
    /** The latest acceptable date */
    max?: Date;
    /** Adds a required validation to the input. Default: true */
    required?: boolean;
    modelValue?: any;
    outerClass?: string;
  }>(),
  {
    required: true,
    help: undefined,
    label: undefined,
    validationVisibility: undefined,
    validationLabel: undefined,
    min: () => new Date(1900, 0, 1),
    max: undefined,
    modelValue: undefined,
    outerClass: undefined,
  },
);

defineEmits<{
  (eventName: "update:modelValue", input: void): void;
}>();

const getValidation = (): Array<[rule: string, ...args: any]> => {
  const validation: Array<[rule: string, ...args: any]> = [];
  if (props.required) validation.push(["required"]);

  if (props.min && props.max) {
    validation.push([
      "date_between",
      formatInputDate(props.min),
      formatInputDate(props.max),
    ]);
  }

  if (props.min && !props.max) {
    validation.push(["date_after", formatInputDate(subDays(props.min, 1))]);
  }

  if (!props.min && props.max) {
    validation.push(["date_before", formatInputDate(addDays(props.max, 1))]);
  }

  return validation;
};

const getDateBetweenValidationMessage = () => {
  if (!props.min || !props.max) return "";
  return `${props.validationLabel ?? "Datum"} muss zwischen ${formatDate(
    props.min,
  )} und ${formatDate(props.max)} liegen.`;
};

const getDateAfterValidationMessage = () => {
  if (!props.min) return "";
  return `${props.validationLabel ?? "Datum"} muss nach dem ${formatDate(
    subDays(props.min, 1),
  )} liegen.`;
};

const getDateBeforeValidationMessage = () => {
  if (!props.max) return "";
  if (isToday(props.max))
    return `${props.validationLabel ?? "Datum"} darf nicht in der Zukunft liegen.`;
  return `${props.validationLabel ?? "Datum"} muss vor dem ${formatDate(
    addDays(props.max, 1),
  )} liegen.`;
};

/** Transforms the date into a format that is accepted by the HTML input field. */
const formatInputDate = (date?: Date) => {
  if (date) return format(date, "yyyy-MM-dd");
  return "";
};
</script>
